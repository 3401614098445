<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link v-if="school" class="brand-logo">
          <vuexy-logo />
        </b-link>

        <b-form class="auth-login-form mt-3" @submit.prevent="login">
          <b-form-group label-for="email">
            <b-input-group class="input-group-merge" :class="{ 'is-invalid': $v.user.password.$error }">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  E-mail 
                </div>
              </div>
              <input
                type="email"
                v-model="user.email"
                name="email"
                placeholder="nome@empresa.com.br"
                class="form-control pl-1"
                required
                :class="{ 'is-invalid': $v.user.email.$error }"
                autocomplete="off"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group>
            <b-input-group class="input-group-merge" :class="{ 'is-invalid': $v.user.password.$error }">
              <div class="input-group-prepend">
                <div class="input-group-text">
                  Senha 
                </div>
              </div>
              <b-form-input
                autocomplete="off"
                id="password"
                v-model="user.password"
                :type="passwordFieldType"
                class="form-control-merge pl-1"
                name="login-password"
                placeholder="Senha"
                required
                :class="{ 'is-invalid': $v.user.password.$error }"
              />
              <div class="input-group-append">
                <div class="input-group-text">
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </div>
              </div>
            </b-input-group>
            <div class="d-flex justify-content-end mt-1">
              <b-link :to="{ name: 'auth-user-forgot-password' }">
                <small>
                  <feather-icon
                    class="cursor-pointer"
                    icon="LockIcon"/>
                  Recuperar senha
                </small>
              </b-link>
            </div>
          </b-form-group>

          <b-button :disabled="loading" variant="primary" type="submit" block>
            <div v-if="loading">
              <b-spinner
                small
                variant="light"
              /> Verificando...
            </div>
            <div v-else>
              Entrar
            </div>
          </b-button>
        </b-form>

      </b-card>
      <p class="text-center mt-1">
        Seducar - Plataforma de EAD
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BSpinner,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { required, minLength } from 'vuelidate/lib/validators'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    VuexyLogo,
    BCardText,
    BInputGroup,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  validations: {
    user: {
      email: {
        required,
        minLength: minLength(11),
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapState('School', ['school']),
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    login() {
      this.$v.$touch();

      localStorage.removeItem('refreshToken');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');

      if (!this.$v.$error) {
        this.loading = true;
        useJwt
          .loginUser({
            email: this.user.email,
            password: this.user.password,
          })
          .then((res) => {
            const { token, userData } = res.data;
            this.$ability.update([]);
            useJwt.setToken(token.token);
            useJwt.setRefreshToken(token.token);
            localStorage.setItem('userData', JSON.stringify(userData));
            this.$ability.update(userData.ability);

            this.$router
              .replace(getHomeRouteForLoggedInUser(userData.role))
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `Olá ${userData.firstName}`,
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Bem-vindo de volta. Espero que seu dia seja produtivo.',
                  },
                },
                {
                  position: 'top-center',
                  timeout: 4000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  hideProgressBar: true,
                  showCloseButtonOnHover: true,
                });
                this.$router.push({ name: 'dashboard-ecommerce' });
              });
          })
          .catch(() => {
            this.loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Ops...`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'E-mail ou Senha incorretos. Tente novamente.',
              },
            },
            {
              position: 'top-center',
              timeout: 4000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              hideProgressBar: true,
              showCloseButtonOnHover: true,
            });
          });
      }
    },
  },
  created() {
    this.$store.dispatch('School/fetchSchool');
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
