var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-wrapper auth-v1 px-2" }, [
    _c(
      "div",
      { staticClass: "auth-inner py-2" },
      [
        _c(
          "b-card",
          { staticClass: "mb-0" },
          [
            _vm.school
              ? _c(
                  "b-link",
                  { staticClass: "brand-logo" },
                  [_c("vuexy-logo")],
                  1
                )
              : _vm._e(),
            _c(
              "b-form",
              {
                staticClass: "auth-login-form mt-3",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.login.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-form-group",
                  { attrs: { "label-for": "email" } },
                  [
                    _c(
                      "b-input-group",
                      {
                        staticClass: "input-group-merge",
                        class: { "is-invalid": _vm.$v.user.password.$error },
                      },
                      [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("div", { staticClass: "input-group-text" }, [
                            _vm._v(" E-mail "),
                          ]),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.user.email,
                              expression: "user.email",
                            },
                          ],
                          staticClass: "form-control pl-1",
                          class: { "is-invalid": _vm.$v.user.email.$error },
                          attrs: {
                            type: "email",
                            name: "email",
                            placeholder: "nome@empresa.com.br",
                            required: "",
                            autocomplete: "off",
                          },
                          domProps: { value: _vm.user.email },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.user, "email", $event.target.value)
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  [
                    _c(
                      "b-input-group",
                      {
                        staticClass: "input-group-merge",
                        class: { "is-invalid": _vm.$v.user.password.$error },
                      },
                      [
                        _c("div", { staticClass: "input-group-prepend" }, [
                          _c("div", { staticClass: "input-group-text" }, [
                            _vm._v(" Senha "),
                          ]),
                        ]),
                        _c("b-form-input", {
                          staticClass: "form-control-merge pl-1",
                          class: { "is-invalid": _vm.$v.user.password.$error },
                          attrs: {
                            autocomplete: "off",
                            id: "password",
                            type: _vm.passwordFieldType,
                            name: "login-password",
                            placeholder: "Senha",
                            required: "",
                          },
                          model: {
                            value: _vm.user.password,
                            callback: function ($$v) {
                              _vm.$set(_vm.user, "password", $$v)
                            },
                            expression: "user.password",
                          },
                        }),
                        _c("div", { staticClass: "input-group-append" }, [
                          _c(
                            "div",
                            { staticClass: "input-group-text" },
                            [
                              _c("feather-icon", {
                                staticClass: "cursor-pointer",
                                attrs: { icon: _vm.passwordToggleIcon },
                                on: { click: _vm.togglePasswordVisibility },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end mt-1" },
                      [
                        _c(
                          "b-link",
                          {
                            attrs: {
                              to: { name: "auth-user-forgot-password" },
                            },
                          },
                          [
                            _c(
                              "small",
                              [
                                _c("feather-icon", {
                                  staticClass: "cursor-pointer",
                                  attrs: { icon: "LockIcon" },
                                }),
                                _vm._v(" Recuperar senha "),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-button",
                  {
                    attrs: {
                      disabled: _vm.loading,
                      variant: "primary",
                      type: "submit",
                      block: "",
                    },
                  },
                  [
                    _vm.loading
                      ? _c(
                          "div",
                          [
                            _c("b-spinner", {
                              attrs: { small: "", variant: "light" },
                            }),
                            _vm._v(" Verificando... "),
                          ],
                          1
                        )
                      : _c("div", [_vm._v(" Entrar ")]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("p", { staticClass: "text-center mt-1" }, [
          _vm._v(" Seducar - Plataforma de EAD "),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }